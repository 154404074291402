<template>
	<div id="userInfo" class="el-content">
		<a-form ref="form">
			<div class="kd-user-li">
				<div class="kd-user-li-title">头像</div>
				<div class="kd-user-li-head">
					<img class="kd-pic-url" v-if="info.pic_url" :src="info.pic_url" alt="" />
					<img class="kd-pic-url" v-else src="../../assets/img/moren.png" alt="" />
				</div>
				<div class="kd-user-li-opration">
					<kd-img-select :custom="true" @success="(e)=>{info.pic_url = e}">
						<a-button type="link">修改</a-button>
					</kd-img-select>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">用户</div>
				<div class="kd-user-li-head">
					<a-input v-if="update_type == 1" v-model:value="info.name"></a-input>
					<span v-else>{{ info.name }}</span>
				</div>
				<div class="kd-user-li-opration">
					<a-button @click="update_type = 1" type="link">修改</a-button>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">手机号</div>
				<div class="kd-user-li-head">
					<a-input v-if="update_type == 2" v-model:value="info.mobile"></a-input>
					<span v-else>{{ info.mobile }}</span>
				</div>
				<div class="kd-user-li-opration"><a-button @click="update_type = 2" type="link">修改</a-button></div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">密码</div>
				<div class="kd-user-li-head"><span>******</span></div>
				<div class="kd-user-li-opration"><a-button @click="passwordDialog = true" type="link">修改</a-button></div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">可创建小程序个数</div>
				<div class="kd-user-li-head">
					<span>{{ info.mini_limit }}</span>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">邮箱</div>
				<div class="kd-user-li-head">
					<a-input v-if="update_type == 3" v-model:value="info.email"></a-input>
					<span v-else>{{ info.email }}</span>
				</div>
				<div class="kd-user-li-opration"><a-button @click="update_type = 3" type="link">修改</a-button></div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">最后登录时间</div>
				<div class="kd-user-li-head">
					<span>{{ info.login_time }}</span>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">最后登录IP</div>
				<div class="kd-user-li-head">
					<span>{{ info.last_login_ip }}</span>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">到期时间</div>
				<div class="kd-user-li-head">
					<span v-if="info.is_permanent == 1">永久</span>
					<span v-else>{{ info.validity_time }}</span>
				</div>
			</div>
			<a-form-item style="margin-left: 10%;">
				<a-space>
					<a-button type="primary" @click="saveUserInfo">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
		<a-modal v-model:visible="passwordDialog" title="修改密码" @ok="savePassWord" @cancel="passwordDialog = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="新密码"><a-input v-model:value="passwordForm.password"></a-input></a-form-item>
				<a-form-item label="再次新密码"><a-input v-model:value="passwordForm.re_password"></a-input></a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import userModel from '@/api/user';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			user_id: 0,
			info: [],
			update_type: 0, //1名称 2电话 3邮箱
			passwordDialog: false,
			passwordForm: {
				password: '',
				re_password: ''
			}
		})

		userModel.getUserDetail("").then(res=>state.info = res)

		function saveUserInfo(){
			let data = {
				pic_url: state.info.pic_url,
				name: state.info.name,
				mobile: state.info.mobile,
				email: state.info.email,
				user_id: state.info.user_id,
				type: 1,
				rank: 3,
				scene: 'edit',
				is_self: 1
			}

			userModel.addOrEditChildUser(data,()=>{})
		}

		function savePassWord(){
			let { passwordForm, info } = state
			if( !tool.returnMessage(passwordForm.password,'请输入新密码')) return
			if( !tool.returnMessage(passwordForm.re_password,'请再次输入新密码')) return
			if( passwordForm.password !=passwordForm.re_password ){
				tool.message("两次输入密码不一致","warning")
				return
			}
			let data = {
				user_id: info.user_id,
				scene: 'password',
				password: passwordForm.password,
				rank: 3,
				type: 1
			}
			userModel.addOrEditChildUser(data,()=>{
				state.passwordDialog = false
			})
		}

		return{
			...toRefs(state),
			saveUserInfo,
			savePassWord
		}
	}
}
</script>

<style lang="scss">
.kd-user-li {
	width: 100%;
	height: 60px;
	line-height: 60px;
	display: flex;
	font-size: 14px;
	color: #868686;
	border-bottom: 1px solid #f1eded;

	&-title {
		width: 10%;
	}
	&-head {
		width: 50%;
	}
	&-opration {
		width: 40%;
		text-align: right;
		color: #0074d9;
		cursor: pointer;
	}
}
.kd-pic-url {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
</style>
